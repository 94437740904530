/* Selector */
.selector-container {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 20px;
  z-index: 1;
}
.selector {
  padding: 10px;
  margin: 0 20px 10px;
  width: 10em;
  border-radius: 10px;
  font-weight: 700;
  cursor: pointer;
}

.active-selector {
  background-color: green;
  color: white;
}

.active-display {
  visibility: visible;
}

.hidden-display {
  visibility: hidden;
}

/* 2d circle approach */
.ball-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  perspective: "500px";
}

.ball {
  display: block;
  background: black;
  border-radius: 50%;
  height: 65vh;
  width: 65vh;
  background: radial-gradient(circle at 100px 100px, #5cabff, #000);
}

.shadow {
  background: radial-gradient(#000f, #fff0 72%);
  height: 5vh;
  width: 45vh;
  position: absolute;
  margin-top: 70vh;
  margin-left: 30vh;
}

/* 2d text approach */
.text-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  perspective: "500px";
}

.text {
  display: block;
  color: black;
  user-select: none;
  text-align: center;
  font-size: 10em;
  text-shadow: 5px 5px #558abb;
}

.text-shadow {
  color: #333;
  height: 5vh;
  width: 45vh;
  position: absolute;
  margin-top: 40vh;
  margin-left: 30vh;
  transform: rotate3d(1, 0, 0, 70deg) rotate3d(0,0,1,15deg);
  font-size: 5em;
  transform-style: "preserve-3d",
}

/* Isohedron approach */
.scene {
  perspective: 400vmin;
  transform-style: preserve-3d;
  position: absolute;
  width: 80vmin;
  height: 80vmin;
  top: 10vmin;
  left: 10vmin;
}

.sphere {
  transform-style: preserve-3d;
  position: absolute;
  animation: rotate 20s infinite linear;
  width: 100%;
  height: 100%;
  transform-origin: 50% 50%;
  top: 0;
  left: 0;
}

.scene::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  background: radial-gradient(
      circle farthest-corner at 33% 33%,
      rgba(240, 240, 220, 0.85) 0%,
      rgba(30, 30, 40, 0.85) 80%
    ),
    radial-gradient(
      circle farthest-corner at 45% 45%,
      rgba(0, 0, 0, 0) 50%,
      #000000 80%
    );
  border-radius: 50%;
  transform: translateZ(2vmin);
}

.hemisphere {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transform-origin: 50% 50%;
  transform: rotateX(90deg);
}

.hemisphere:nth-child(2) {
  transform: rotateX(-90deg);
}

.face {
  position: absolute;
  width: 40vmin;
  height: 40vmin;
  background: radial-gradient(
    circle at 50% 50%,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.1) 48%,
    #00a2ff 49%,
    #00a2ff 50%,
    rgba(0, 0, 0, 0) 51%
  );
  transform-style: preserve-3d;
  transform-origin: 50% 0;
  top: 50%;
  left: 20vmin;
}

.face::before,
.face::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  box-sizing: border-box;
}

.face::before {
  width: 50%;
  height: 50%;
  top: 25%;
  left: 25%;
  border: 2px solid #333;
  background: rgba(255, 255, 255, 0.3);
  transform: translateZ(1.6vmin);
}

.face::after {
  width: 20%;
  height: 20%;
  top: 40%;
  left: 40%;
  background: rgba(0, 0, 0, 0.2);
  transform: translateZ(2.8vmin);
}

.face:nth-child(1) {
  transform: translateZ(-41.6vmin) rotateZ(36deg) translateY(-6.8vmin)
    rotateX(143deg);
}

.face:nth-child(2) {
  transform: translateZ(-41.6vmin) rotateZ(108deg) translateY(-6.8vmin)
    rotateX(143deg);
}

.face:nth-child(3) {
  transform: translateZ(-41.6vmin) rotateZ(180deg) translateY(-6.8vmin)
    rotateX(143deg);
}

.face:nth-child(4) {
  transform: translateZ(-41.6vmin) rotateZ(252deg) translateY(-6.8vmin)
    rotateX(143deg);
}

.face:nth-child(5) {
  transform: translateZ(-41.6vmin) rotateZ(-36deg) translateY(-6.8vmin)
    rotateX(143deg);
}

.face:nth-child(6) {
  transform: translateZ(-26.8vmin) rotateZ(36deg) translateY(-33.2vmin)
    rotateX(100deg);
}

.face:nth-child(7) {
  transform: translateZ(-26.8vmin) rotateZ(108deg) translateY(-33.2vmin)
    rotateX(100deg);
}

.face:nth-child(8) {
  transform: translateZ(-26.8vmin) rotateZ(180deg) translateY(-33.2vmin)
    rotateX(100deg);
}

.face:nth-child(9) {
  transform: translateZ(-26.8vmin) rotateZ(252deg) translateY(-33.2vmin)
    rotateX(100deg);
}

.face:nth-child(10) {
  transform: translateZ(-26.8vmin) rotateZ(-36deg) translateY(-33.2vmin)
    rotateX(100deg);
}

.face:nth-child(11) {
  transform: translateZ(-26.8vmin) rotateZ(36deg) translateY(-33.2vmin)
    rotateX(100deg);
}

/* Cross Circle */
.cross-scene {
  perspective: 1000px;
  transform-style: preserve-3d;
  position: absolute;
  width: 80vmin;
  height: 80vmin;
  top: 10vmin;
  left: 10vmin;
}
.clip {
  clip-path: circle(48%);
}
.cross-sphere {
  margin-top: 5vh;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 40vmin;
  height: 40vmin;
  transform-style: preserve-3d;
}
.cross-border {
  border: 1px solid black;
}

.circle {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #00a2ff;
}
/* X Axis */
.circle:nth-child(1) {
  transform: rotate3d(1, 0, 0, 0deg);
}
.circle:nth-child(2) {
  transform: rotate3d(1, 0, 0, 36deg);
}
.circle:nth-child(3) {
  transform: rotate3d(1, 0, 0, 72deg);
}
.circle:nth-child(4) {
  transform: rotate3d(1, 0, 0, 108deg);
}
.circle:nth-child(5) {
  transform: rotate3d(1, 0, 0, 144deg);
}
/* Y Axis */
.circle:nth-child(6) {
  transform: rotate3d(0, 1, 0, 0deg);
}
.circle:nth-child(7) {
  transform: rotate3d(0, 1, 0, 36deg);
}
.circle:nth-child(8) {
  transform: rotate3d(0, 1, 0, 72deg);
}
.circle:nth-child(9) {
  transform: rotate3d(0, 1, 0, 108deg);
}
.circle:nth-child(10) {
  transform: rotate3d(0, 1, 0, 144deg);
}
/* Z Axis */
.circle:nth-child(11) {
  transform: rotate3d(0, 1, 0, 90deg) rotate3d(1, 0, 0, 0deg);
}
.circle:nth-child(12) {
  transform: rotate3d(0, 1, 0, 90deg) rotate3d(1, 0, 0, 36deg);
}
.circle:nth-child(13) {
  transform: rotate3d(0, 1, 0, 90deg) rotate3d(1, 0, 0, 72deg);
}
.circle:nth-child(14) {
  transform: rotate3d(0, 1, 0, 90deg) rotate3d(1, 0, 0, 108deg);
}
.circle:nth-child(15) {
  transform: rotate3d(0, 1, 0, 90deg) rotate3d(1, 0, 0, 144deg);
}

@media (max-width: 680px) {
  .ball {
    height: 80vw;
    width: 80vw;
  }

  .shadow {
    margin-top: 100vw;
    height: 10vw;
    width: 70vw;
  }

  .scene {
    top: 30vmax;
  }

  .cross-scene {
    top: 30vmax;
  }
}
